// eslint-disable-next-line no-undef
window._config = {
  baseUrl: 'https://ci.login.bandwidth.com',
  dashboard: 'https://qa.dashboard.bandwidth.com',
  irisApi: 'https://qa.dashboard.bandwidth.com/api/v2',
  oneIdManagementApi: 'https://ci.lab.management.id.bandwidth.com',
  oidc: {
    issuer: 'https://ci.login.bandwidth.com/oauth2/ausqxqyu1tW6zVd850h7',
    clientId: '0oavvd0gtxnnYhavp0h7',
    redirectUri: 'https://ci.passport.bandwidth.com/login',
    postLogoutRedirectUri: 'https://ci.passport.bandwidth.com/logout',
    scopes: ['openid', 'oneid', 'email', 'profile'],
    pkce: true,
    storageManager: {
      token: {
        storageTypes: ['sessionStorage'],
      },
    },
  },
  cookies: {
    secure: true,
  },
  datadog: {
    env: 'qa',
    applicationId: 'a8c3461d-e18a-4539-9b0f-4f4833918058',
    clientToken: 'pub81d618dcf76d9080292c09d3a983fe21',
  },
};
